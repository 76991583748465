import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import PropTypes from 'prop-types';
import {Close} from '@material-ui/icons';
import placeholderButton from 'components/Editor/placeholderButton';
import Editor from 'components/Editor';

export default function SendMail({
  open,
  onClose,
  assignment,
  assignmentSubjectIds,
  columns = [],
  type,
  history,
}) {
  const intl = useIntl();
  const client = new graphQLApi(useAuthDispatch(), history);
  const [_isLoading, setIsLoading] = useState(false);
  const mailDefault = {
    body: '',
    subject: '',
    to_name: '',
    to_email: '',
    from_name: '',
    from_email: '',
  };
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [mail, setMail] = useState(mailDefault);

  const placeholders = [
    {
      id: 'subjectsCount',
      type: 'static',
      name: intl.formatMessage({
        id: 'templates.editor.placeholder.subjectsCount',
        defaultMessage: 'Number of subjects',
      }),
    },
  ];

  useEffect(() => {
    setMail(mailDefault);
    if (!assignment) {
      return;
    }
    setIsLoading(true);
    let subject = '';
    let attachment = '';
    switch (type) {
      case 'table':
        subject = intl.formatMessage({
          id: "assignment.dialogs.send_mail.table.subject",
          defaultMessage: 'Here is «subjectsCount» subjects for you'
        }, assignment);
        attachment = <Icon className="fa fa-file-excel" />;
        break;
      case 'report':
        subject = intl.formatMessage({
          id: "assignment.dialogs.send_mail.report.subject",
          defaultMessage: 'Here is «subjectsCount» meetings for you'
        }, assignment);
        attachment = <Icon className="fa fa-file-pdf" />;
        break;
      default:
      case 'calendar':
        subject = intl.formatMessage({
          id: "assignment.dialogs.send_mail.calendar.subject",
          defaultMessage: 'Assignment {name} booked meetings calendar'
        }, assignment);
        attachment = <Icon className="fa fa-file-contract" />;
        break;
    }
    client.query('{' +
        'assignmentEmailSettings(filter:{assignment_id:' + assignment.id + '})' +
        '{data{report_receiver_email report_sender_name report_sender_email signature}}' +
        '}').then(r => {
      if (r && r.hasOwnProperty('assignmentEmailSettings')) {
        // let body = r.assignmentEmailSettings.data[0].signature;
        // placeholders.forEach(option => {
        //   const expression = new RegExp(`(&#171;|«)(Q\\d+: ){0,1}${option.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(&#187;|»)`, 'g');
        //
        //   body = body.replace(expression, `&#171;${option.type === 'question' ? 'Q' : ''}${option.id}&#187;`);
        // });

        placeholders.forEach(option => {
          const expression = new RegExp(`(&#171;|«)(Q\\d+: ){0,1}${option.id.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(&#187;|»)`, 'g');
          subject = subject.replace(expression, `«${option.type === 'question' ? 'Q' : ''}${option.name}»`);
        });
        setMail({
          body: r.assignmentEmailSettings.data[0].signature,
          subject: subject,
          to_email: r.assignmentEmailSettings.data[0].report_receiver_email,
          from_name: r.assignmentEmailSettings.data[0].report_sender_name,
          from_email: r.assignmentEmailSettings.data[0].report_sender_email,
          attachments: [
              attachment
          ]
        });
      }
      setIsLoading(false);
    });
  }, [type, assignment]);

  const handleSendMail = () => {
    setIsSendingMail(true);
    let body = mail.body;
    let subj = mail.subject;
    placeholders.forEach(option => {
      const expression = new RegExp(`(&#171;|«)(Q\\d+: ){0,1}${option.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(&#187;|»)`, 'g');

      body = body?.replace(expression, `&#171;${option.type === 'question' ? 'Q' : ''}${option.id}&#187;`);
      subj = subj?.replace(expression, `&#171;${option.type === 'question' ? 'Q' : ''}${option.id}&#187;`);
    });
    client
      .mutate('($id:ID $type:String! $ids:[ID] $columns:[String] $subject:String! $body:String! $to_email:String) {' +
        'assignmentSend(assignment_id:$id type:$type assignment_subject_ids:$ids columns:$columns subject:$subject body:$body to_email:$to_email)' +
        '}', {
        id: assignment.id,
        type: type,
        ids: assignmentSubjectIds,
        columns: columns,
        subject: subj,
        body: body,
        to_email: mail.to_email,
      })
      .then(r => {
        setIsSendingMail(false);
        if (r && r.hasOwnProperty('assignmentSend')) {
          onClose(r.assignmentSend);
        }
      });
  }

  return <Dialog open={open} onClose={onClose} maxWidth="xl">
    <DialogTitle disableTypography
                 style={{display: 'flex', justifyContent: 'space-between'}}>
      <Typography variant="h2" style={{width: 300}}>{intl.formatMessage({
        id: 'assignment.dialogs.send_mail.title',
        defaultMessage: 'Send mail to customer',
      })}</Typography>
      {isSendingMail ? <CircularProgress/> :
      <Button
          disabled={!mail.body || !mail.to_email}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleSendMail()}
      >{intl.formatMessage({
          id: 'assignment.dialogs.send_mail.button',
          defaultMessage: 'Send mail',
        })}</Button>}
      <Box style={{width: 300, textAlign: 'right'}}><IconButton size="small"
                                                                onClick={onClose}><Close/></IconButton></Box>
    </DialogTitle>
    <DialogContent
        style={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}>
      {/*<Backdrop open={isLoading}>*/}
      <Grid container spacing={1}>
        <Grid item container xs={8} spacing={1}>
          <Grid item xs={12}>
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'assignment.dialogs.send_mail.label.to_email',
                  defaultMessage: 'To email',
                })}
                value={mail.to_email}
                onChange={e => setMail({...mail, to_email: e.target.value})}
                type="email"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'assignment.dialogs.send_mail.label.from_name',
                  defaultMessage: 'From name',
                })}
                value={mail.from_name}
                disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                fullWidth
                size="small"
                type="email"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'assignment.dialogs.send_mail.label.from_email',
                  defaultMessage: 'From email',
                })}
                value={mail.from_email}
                disabled
                InputProps={{
                  startAdornment: <InputAdornment
                      position="start">{'<'}</InputAdornment>,
                  endAdornment: <InputAdornment
                      position="end">{'>'}</InputAdornment>,
                }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={12}>
            <InputLabel style={{marginBottom: 6}}>{intl.formatMessage({
              id: 'assignment.dialogs.send_mail.label.attachments',
              defaultMessage: 'Attachments',
            })}</InputLabel>
            {mail.attachments && mail.attachments.map(
                (a,k) => <Typography key={'attachment_' + k} style={{display:'block'}}>{a}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              size="small"
              variant="outlined"
              label={intl.formatMessage({
                id: 'assignment.dialogs.send_mail.label.subject',
                defaultMessage: 'Subject',
              })}
              value={mail.subject}
              onChange={e => setMail({...mail, subject: e.target.value})}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel style={{marginBottom: 10}}>{intl.formatMessage({
            id: 'assignment.dialogs.send_mail.label.body',
            defaultMessage: 'Body',
          })}</InputLabel>
          <FormControl
              fullWidth
              style={{overflow: 'clip'}}
          >
            <Editor
              value={mail.body}
              onChange={newContent => setMail({...mail, body: newContent})}
              config={{extraButtons: [placeholderButton(intl, placeholders)]}}
              filebrowser
              model='assignment'
              modelId={assignment.id}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/*</Backdrop>*/}
    </DialogContent>
  </Dialog>;
}

SendMail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  assignment: PropTypes.object.isRequired,
  assignmentSubjectIds: PropTypes.array.isRequired,
  columns: PropTypes.array,
  type: PropTypes.oneOf(
      ['table', 'report', 'calendar']).isRequired,
  history: PropTypes.object.isRequired,
};

import {
  AccountTree,
  Assignment,
  AssignmentInd,
  Business,
  Domain,
  Flare,
  Home as HomeIcon,
  Input,
  Language, ListAlt,
  Person,
  Settings,
} from '@material-ui/icons'
import AssignmentEdit from 'views/Admin/Assignments/AssignmentEdit'
import AssignmentList from 'views/Admin/Assignments/AssignmentList'
import CustomerEdit from 'views/Admin/Customers/CustomerEdit'
import CustomerList from 'views/Admin/Customers/CustomerList'
import Dashboard from 'views/Admin/Dashboard'
import DomainEdit from 'views/Admin/MutliSite/DomainEdit'
import SiteEdit from 'views/Admin/MutliSite/SiteEdit'
import SiteList from 'views/Admin/MutliSite/SiteList'
import DepartmentEdit from 'views/Admin/Settings/DepartmentEdit'
import DepartmentList from 'views/Admin/Settings/DepartmentList'
import LanguageEdit from 'views/Admin/Settings/LanguageEdit'
import LanguageList from 'views/Admin/Settings/LanguageList'
import OutcomeEdit from 'views/Admin/Settings/OutcomeEdit'
import OutcomeList from 'views/Admin/Settings/OutcomeList'
import RoleEdit from 'views/Admin/Settings/RoleEdit'
import RoleList from 'views/Admin/Settings/RoleList'
import SubjectEdit from 'views/Admin/Subjects/SubjectEdit'
import SubjectFieldEdit from 'views/Admin/Subjects/SubjectFieldEdit'
import SubjectFieldList from 'views/Admin/Subjects/SubjectFieldList'
import SubjectList from 'views/Admin/Subjects/SubjectList'
import UserEdit from 'views/Admin/Users/UserEdit'
import UserList from 'views/Admin/Users/UserList'

import {rolePriorities} from 'config'
import MappingEdit from '../views/Admin/Mappings/MappingEdit'
import MappingList from '../views/Admin/Mappings/MappingList'
import SettingsList from "views/Admin/Settings/SettingsList";
import SettingsEdit from "views/Admin/Settings/SettingsEdit";
import SipList from "../views/Admin/Sip/SipList";

export default function routes(intl) {
  const prefix = '/admin';
  return [
    {
      path: "",
      exact: true,
      name: intl.formatMessage({
        id: "menu.system_admin",
        defaultMessage: "System",
      }),
      icon: HomeIcon,
      component: Dashboard,
    },
    {
      path: "/sites",
      name: intl.formatMessage({
        id: "menu.sites.list",
        defaultMessage: "Sites",
      }),
      icon: Domain,
      component: SiteList,
      allow_priority: rolePriorities.site_manager,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.sites.create",
            defaultMessage: "Create Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/create",
          name: intl.formatMessage({
            id: "menu.domains.create",
            defaultMessage: "Create Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/:id",
          name: intl.formatMessage({
            id: "menu.domains.edit",
            defaultMessage: "Edit Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.sites.edit",
            defaultMessage: "Edit Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
      ],
    },
    {
      path: "/assignments",
      name: intl.formatMessage({id: "menu.assignments", defaultMessage: "Assignments"}),
      icon: Assignment,
      component: AssignmentList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.assignments.create", defaultMessage: "Create Assignment"}),
          component: AssignmentEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.assignments.edit", defaultMessage: "Edit Assignment"}),
          component: AssignmentEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/customers",
      name: intl.formatMessage({id: "menu.customers.list", defaultMessage: "Customers"}),
      icon: Business,
      component: CustomerList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.customers.create", defaultMessage: "Create Customer"}),
          component: CustomerEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.customers.edit", defaultMessage: "Edit Customer"}),
          component: CustomerEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/subjects",
      name: intl.formatMessage({id: "menu.subjects.list", defaultMessage: "Subjects"}),
      icon: Business,
      component: SubjectList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.subjects.create", defaultMessage: "Create Subject"}),
          component: SubjectEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.subjects.edit", defaultMessage: "Edit Subject"}),
          component: SubjectEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/mappings",
      name: intl.formatMessage({id: "menu.mappings", defaultMessage: "Mappings"}),
      icon: Business,
      component: MappingList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.mappings.create", defaultMessage: "Create Subject"}),
          component: MappingEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.mappings.edit", defaultMessage: "Edit Subject"}),
          component: MappingEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/settings",
      name: intl.formatMessage({
        id: "menu.settings",
        defaultMessage: "Settings",
      }),
      icon: Settings,
      roles: ["sysdev", "admin", "sites_manager"],
      views: [
        {
          path: "/subjects/subject_fields",
          name: intl.formatMessage({id: "menu.subject_fields.list", defaultMessage: "Subject Fields"}),
          icon: Input,
          component: SubjectFieldList,
          roles: ["sysdev","admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.subject_fields.create", defaultMessage: "Create Subject Field"}),
              component: SubjectFieldEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.subject_fields.edit", defaultMessage: "Edit Subject Field"}),
              component: SubjectFieldEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/outcomes",
          name: intl.formatMessage({id: "menu.outcomes.list", defaultMessage: "Outcomes"}),
          icon: Flare,
          component: OutcomeList,
          roles: ["sysdev","admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.outcomes.create", defaultMessage: "Create Outcome"}),
              component: OutcomeEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.outcomes.edit", defaultMessage: "Edit Outcome"}),
              component: OutcomeEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/departments",
          name: intl.formatMessage({id: "menu.departments.list", defaultMessage: "Departments"}),
          icon: AccountTree,
          component: DepartmentList,
          roles: ["sysdev","admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.departments.create", defaultMessage: "Create Department"}),
              component: DepartmentEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.departments.edit", defaultMessage: "Edit Department"}),
              component: DepartmentEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/languages",
          name: intl.formatMessage({id: "menu.languages.list", defaultMessage: "Languages"}),
          icon: Language,
          component: LanguageList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.languages.create", defaultMessage: "Create Language"}),
              component: LanguageEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.languages.edit", defaultMessage: "Edit Language"}),
              component: LanguageEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/roles",
          name: intl.formatMessage({id: "menu.roles.list", defaultMessage: "Roles"}),
          icon: AssignmentInd,
          component: RoleList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.roles.create", defaultMessage: "Create Role"}),
              component: RoleEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.roles.edit", defaultMessage: "Edit Role"}),
              component: RoleEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/users",
          name: intl.formatMessage({
            id: "menu.users.list",
            defaultMessage: "Users",
          }),
          icon: Person,
          component: UserList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({
                id: "menu.users.create",
                defaultMessage: "Create user",
              }),
              component: UserEdit,
              hidden: true
            },
            {
              path: "/:id",
              name: intl.formatMessage({
                id: "menu.users.edit",
                defaultMessage: "Edit user",
              }),
              component: UserEdit,
              hidden: true
            },
          ],
        }, {
          path: "/sip",
          name: intl.formatMessage({
            id: "menu.sip.list",
            defaultMessage: "SIP",
          }),
          icon: ListAlt,
          component: SipList,
          roles: ["sysdev", "admin"],
        },
        {
          path: "/system",
          name: intl.formatMessage({
            id: "menu.system.settings.list",
            defaultMessage: "System Registry",
          }),
          icon: Person,
          component: SettingsList,
          allow_priority: rolePriorities.site_manager,
          views: [
            {
              path: "/create",
              name: intl.formatMessage({
                id: "menu.system.settings.create",
                defaultMessage: "Create system registry key",
              }),
              component: SettingsEdit,
              hidden: true
            },
            {
              path: "/:id",
              name: intl.formatMessage({
                id: "menu.system.settings.edit",
                defaultMessage: "Edit system registry key",
              }),
              component: SettingsEdit,
              hidden: true
            },
          ],
        },
      ]
    },
  ].map(r => ({...r, path: prefix + r.path}));
}










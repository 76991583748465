import React, {useEffect, useState} from 'react';
import {CardContent, Chip, Grid, IconButton} from '@material-ui/core';

// @material-ui/icons components
import {Call, CallEnd, Dialpad, Mic, MicOff, PhoneDisabled} from '@material-ui/icons';

// auth user for call logging
// date time
// import MomentUtils from "@date-io/moment";
import moment from 'moment';
import 'moment/locale/da';

// https://www.npmjs.com/package/react-timer-hook
import {useStopwatch} from 'react-timer-hook';
import themeColors from '../../../assets/theme/colors';
import {useIntl} from 'react-intl';
import {CALL_STATUS_ACTIVE, CALL_STATUS_IDLE, CALL_STATUS_STARTING, CALL_STATUS_STOPPING, SIP_STATUS_DISCONNECTED} from '@tinxdk/react-sip';
import {Button} from '@mui/material';

const PhoneDialer = (props) => {
  const {disableDialing, callSession, handleCalling, sipStatus} = props;
  const [_callTime, setCallTime] = useState(null);
  const [showDialPad, setShowDialPad] = useState(false);
  const intl = useIntl();

  // creating 00:00 format for timer
  const {seconds, minutes, start, pause, reset} = useStopwatch({
    autoStart: false,
  });
  const TimerFormat = ({minsValue, secValue}) => {
    const minLeftDigit = minsValue >= 10 ? minsValue.toString()[0] : '0';
    const minRightDigit =
        minsValue >= 10 ? minsValue.toString()[1] : minsValue.toString();
    const secLeftDigit = secValue >= 10 ? secValue.toString()[0] : '0';
    const secRightDigit =
        secValue >= 10 ? secValue.toString()[1] : secValue.toString();
    return (
        <>
        <span>
          {minLeftDigit}
          {minRightDigit}:{secLeftDigit}
          {secRightDigit}
        </span>
        </>
    );
  };
  // variable to pass to call duration display
  const callCount = (
      <TimerFormat minsValue={minutes} secValue={seconds}></TimerFormat>
  );

  const callTimeFormatted = `${callCount.props.minsValue.toString().padStart(2, 0)}:${callCount.props.secValue.toString().padStart(2, 0)}`;

  moment.locale('da');

  useEffect(() => {
    setCallTime(callTimeFormatted);
  }, [callCount]);

  const dial = () => {
    handleCalling(true);
    start();
  };

  const hangup = () => {
    handleCalling(false);
    pause();
    setTimeout(() => {
      reset('', false);
    }, 2000);
  };

  useEffect(() => {
    if (!(callSession?.status !== CALL_STATUS_IDLE)) {
      pause();
      reset('', false);
      setShowDialPad(false);
    }
  }, [callSession]);

  const getStatusMessage = () => {
    if (sipStatus === SIP_STATUS_DISCONNECTED) {
      return intl.formatMessage({id: 'dialer.sip_status.disconnected', defaultMessage: 'Disconnected'});
    }
    switch (callSession?.status) {
      case CALL_STATUS_IDLE:
        return intl.formatMessage({id: 'dialer.sip_status.connected', defaultMessage: 'Connected'});
      case CALL_STATUS_ACTIVE:
        return intl.formatMessage({id: 'dialer.sip_status.in_progress', defaultMessage: 'In progress'});
      case CALL_STATUS_STARTING:
        return intl.formatMessage({id: 'dialer.sip_status.dialing', defaultMessage: 'Dialing'});
      case CALL_STATUS_STOPPING:
        return intl.formatMessage({id: 'dialer.sip_status.hanging_up', defaultMessage: 'Hanging up'});
      default:
        return intl.formatMessage({id: 'dialer.sip_status.unknown', defaultMessage: 'Unknown {raw}'}, {raw: callSession?.status});
    }
  };

  return (
      <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
      >
        {callSession
            ? <Grid container justifyContent="flex-end" spacing={2}>
              {showDialPad ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '#'].map(n =>
                      <Grid key={'dtmf-' + n} item xs={4} style={{textAlign: 'center', padding: '0 4px'}}>
                        <Button
                            style={{minWidth: 60, fontSize: 'larger', fontWeight: 'bolder', padding: 0}}
                            onClick={() => {
                              if (callSession.dtmfSender.canInsertDTMF) {
                                console.log('Sending DTMF', n);
                                props.sendDTMF(n);
                              }
                            }}
                        >{n}</Button>
                      </Grid>,
                  ) :
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <IconButton
                        onClick={dial}
                        style={{
                          backgroundColor: (disableDialing || callSession?.status !== CALL_STATUS_IDLE) ? themeColors.gray['500'] : themeColors.success.badgeBg,
                        }}
                        disabled={disableDialing || callSession?.status !== CALL_STATUS_IDLE}
                    >
                      <Call style={{fontSize: 28}}></Call>
                    </IconButton><br/><br/>
                    <Chip
                        color="primary"
                        label={callCount}
                        style={{fontSize: '1.2rem'}}
                    />
                  </Grid>}
              <Grid item xs={12} style={{justifyContent: 'space-around', display: 'flex'}}>
                <IconButton
                    onClick={hangup}
                    style={{
                      backgroundColor: (!callSession || callSession?.status === CALL_STATUS_IDLE) ? themeColors.gray['500'] : themeColors.error.badgeBg,
                    }}
                    disabled={!callSession || callSession?.status === CALL_STATUS_IDLE}
                >
                  <CallEnd style={{fontSize: 28}}></CallEnd>
                </IconButton>
                {callSession.dtmfSender?.canInsertDTMF && <IconButton onClick={() => setShowDialPad(!showDialPad)}><Dialpad/></IconButton>}
                <IconButton
                    onClick={() => {
                      if (callSession?.status === CALL_STATUS_ACTIVE) {
                        if (callSession.microphoneIsMuted) {
                          console.log('Unmuting Microphone');
                          callSession.unmuteMicrophone();
                        }
                        else {
                          console.log('Muting Microphone');
                          callSession.muteMicrophone();
                        }
                      }
                    }}
                    style={{
                      backgroundColor: (callSession?.status === CALL_STATUS_IDLE) ? themeColors.gray['500'] : themeColors.warning.badgeBg,
                    }}
                    disabled={callSession?.status !== CALL_STATUS_ACTIVE}
                >
                  {callSession && callSession.microphoneIsMuted
                      ? <MicOff style={{fontSize: 28}}/>
                      : <Mic style={{fontSize: 28}}/>}
                </IconButton>
              </Grid>
              <Grid item
                    style={{
                      position: 'relative',
                      bottom: -10,
                      textAlign: 'center',
                      margin: -15,
                      fontStyle: 'italic',
                      color: themeColors.gray['600'],
                    }}>{getStatusMessage()}</Grid>
            </Grid>
            : <>
              <Grid item><PhoneDisabled style={{fontSize: 64}}/></Grid>
            </>}
      </CardContent>
  );
};

export default PhoneDialer;

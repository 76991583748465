import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons components

import componentStyles from "assets/theme/views/admin/dashboard.js";
// javascipt plugin for creating charts

// core components
import Header from "components/Headers/Header.js";
import {authUser} from "contexts/Auth";
import React from "react";
// react plugin used to create charts
import AssignmentList from "views/Admin/Assignments/AssignmentList";

const useStyles = makeStyles(componentStyles);

function Dashboard(props) {
  const classes = useStyles();
  // let assignmentId = props.match.params && props.match.params.assignmentID;

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-10rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} style={{textAlign:"center",marginTop:35}}>
            <AssignmentList {...props} filter={"manager_id:" + authUser().id}/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Dashboard;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AppBar, Card, Grid, InputAdornment, Link, Tab, Tabs, TextField} from '@material-ui/core';
import TabPanel from 'views/Dialer/Components/TabPanel';
import {useIntl} from 'react-intl';
import {OpenInNew} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from 'assets/theme/components/dialer-subject';
import {zipCodes} from "../../../config";

const useStyles = makeStyles(componentStyles);

export default function Subject({assignmentSubject, setSubject, subjectFields}) {
  const classes = useStyles();
  const [subjectFieldsTab, setSubjectFieldsTab] = useState(0);
  const intl = useIntl();

  const getSubjectValue = (field) => {
    if (assignmentSubject?.subject && assignmentSubject?.subject[field]) {
      return assignmentSubject.subject[field];
    }
    return '';
  }
  const updateSubjectValue = (field, value) => {
    setSubject({...assignmentSubject, subject: {...assignmentSubject.subject, [field]: value}});
  }
  const getSubjectFieldValue = (fieldId) => {
    if (assignmentSubject?.subject_field_data) {
      let sfd = assignmentSubject.subject_field_data.find(fd => fd.subject_field_id === fieldId);
      if (sfd && sfd.data) {
        return sfd.data;
      }
    }
    return '';
  }
  const updateSubjectFieldValue = (fieldId, value) => {
    let sfdi = assignmentSubject.subject_field_data.findIndex(fd => fd.subject_field_id === fieldId);
    let sfd = [...assignmentSubject.subject_field_data];
    if (sfdi >= 0 && sfd[sfdi]) {
      sfd[sfdi].data = value
    }
    else {
      sfd.push({data: value, subject_field_id: fieldId});
    }
    setSubject({...assignmentSubject, subject_field_data: sfd});
  }

  const showSubjectFieldLink = (field) => {
    const arr = ['cvr', 'adresse', 'address']
    return  arr.some(elm => field.name.toLowerCase().includes(elm))
  }

  const getSubjectFieldUrl = (field) => {
    if (field.name.toLowerCase().includes('cvr'))
      return 'https://datacvr.virk.dk/enhed/virksomhed/' + encodeURIComponent(getSubjectFieldValue(field.id))
    else if (field.name.toLowerCase().includes('adresse') || field.name.toLowerCase().includes('adresse'))
      return 'https://map.krak.dk/s/' + encodeURIComponent(getSubjectFieldValue(field.id))
  }

  return (
    <Card elevation={4} style={{minHeight:353}}>
      <AppBar
        position="static"
      >
        <Tabs
          scrollButtons="on"
          value={subjectFieldsTab}
          onChange={(_e, i) => setSubjectFieldsTab(i)}
          variant="scrollable"
        >
          <Tab label={intl.formatMessage({id:"dialer.card.subject.tabs.general", defaultMessage:"Subject information"})}/>
          {subjectFields.map((sf, k) => <Tab key={'tab_subject_fields_data_'+k} label={sf.name}/>)}
        </Tabs>
      </AppBar>
      <TabPanel value={subjectFieldsTab} index={0} height={"auto"} p={1.5}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="name"
              label={intl.formatMessage({id:"dialer.card.subject.name.label", defaultMessage:"Company name"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('name')}
              onChange={e => updateSubjectValue('name', e.target.value)}
              required
              InputProps={{
                endAdornment: assignmentSubject?.subject?.name && <InputAdornment position={"end"}>
                  <Link
                      href={'https://datacvr.virk.dk/soegeresultater?fritekst='+ encodeURIComponent(assignmentSubject.subject?.name)}
                      target="_blank"
                  ><OpenInNew/></Link>
                </InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="address_1"
              label={intl.formatMessage({id:"dialer.card.subject.address_1.label", defaultMessage:"Address"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('address_1')}
              onChange={e => updateSubjectValue('address_1', e.target.value)}
              InputProps={{
                endAdornment: assignmentSubject.subject?.address_1 && <InputAdornment position={"end"}>
                  <Link
                      href={'https://map.krak.dk/s/'+ encodeURIComponent(assignmentSubject.subject?.address_1 + ', ' +assignmentSubject.subject?.zip + ' ' +assignmentSubject.subject?.city)}
                      target="_blank"
                  ><OpenInNew/></Link>
                </InputAdornment>
              }}
            />
          </Grid>
          {/*<Grid item xs={12}>
            <TextField
              classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="address_2"
              label={intl.formatMessage({id:"dialer.card.subject.address_2.label", defaultMessage:"Address 2"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('address_2')}
              onChange={e => updateSubjectValue('address_2', e.target.value)}
            />
          </Grid>*/}
          <Grid item xs={2}>
            <TextField
                classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="zip"
              label={intl.formatMessage({id:"dialer.card.subject.zip.label", defaultMessage:"Zip"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('zip')}
              onChange={e => updateSubjectValue('zip', e.target.value)}
              onBlur={e => {
                const city = zipCodes.find(zip => zip.id === parseInt(e.target.value))?.name
                updateSubjectValue('city', city)
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
                classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="city"
              label={intl.formatMessage({id:"dialer.card.subject.city.label", defaultMessage:"City"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('city')}
              onChange={e => updateSubjectValue('city', e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
                classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="main_phone"
              label={intl.formatMessage({id:"dialer.card.subject.main_phone.label", defaultMessage:"Main phone"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('main_phone')}
              onChange={e => updateSubjectValue('main_phone', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="email"
              label={intl.formatMessage({id:"dialer.card.subject.email.label", defaultMessage:"Email"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('email')}
              onChange={e => updateSubjectValue('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
                classes={{root: classes.textField}}
                disabled={!assignmentSubject.id}
                fullWidth
                id="website"
                label={intl.formatMessage({id:"dialer.card.subject.website.label", defaultMessage:"Website"})}
                variant="outlined"
                size="small"
                value={getSubjectValue('website')}
                onChange={e => updateSubjectValue('website', e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position={"start"}><span>https://</span></InputAdornment>,
                  endAdornment: getSubjectValue('website').length > 0 && <InputAdornment position={"end"}>
                    <Link
                        href={'https://'+getSubjectValue('website').replace('http://', '').replace('https://', '')}
                        target="_blank"
                    ><OpenInNew/></Link>
                  </InputAdornment>
                }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
                classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="business_type"
              label={intl.formatMessage({id:"dialer.card.subject.business_type.label", defaultMessage:"Business type"})}
              variant="outlined"
              size="small"
              value={getSubjectValue('business_type')}
              onChange={e => updateSubjectValue('business_type', e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
                classes={{root: classes.textField}}
              disabled={!assignmentSubject.id}
              fullWidth
              id="employees"
              label={intl.formatMessage({id:"dialer.card.subject.employees.label", defaultMessage:"Employees"})}
              variant="outlined"
              size="small"
              type="number"
              value={getSubjectValue('employees')}
              onChange={e => updateSubjectValue('employees', parseInt(e.target.value))}
              inputProps={{min:0,step:1}}
            />
          </Grid>
        </Grid>
      </TabPanel>
      {subjectFields.map((sf, k) => <TabPanel key={'tabpanel_subject_fields_data_'+k} value={subjectFieldsTab} index={k+1} p={1.5} height={305}>
        <Grid container spacing={1}>
          {sf.fields.sort((a,b) => {
            let x = a.name.toUpperCase(),
                y = b.name.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          }).map((f,fk) =>
            <TextField
                classes={{root: classes.textField}}
              key={'tabpanel_subject_fields_data_'+k+'_field_'+fk}
              disabled={!assignmentSubject.id}
              fullWidth
              variant="outlined"
              size="small"
              id={'subject_field_'+f.id}
              name={'subject_field_'+f.id}
              label={f.name}
              value={getSubjectFieldValue(subjectFields[k].fields[fk].id)}
              onChange={e => updateSubjectFieldValue(subjectFields[k].fields[fk].id, e.target.value)}
              style={{marginBottom:5}}
              InputProps={{
              endAdornment: showSubjectFieldLink(f) && <InputAdornment position={"end"}>
              <Link
              href={getSubjectFieldUrl(f)}
              target="_blank"
              ><OpenInNew/></Link>
              </InputAdornment>
              }}
            />)}
        </Grid>
      </TabPanel>)}
    </Card>
  );
}

Subject.propTypes = {
  subject: PropTypes.object,
  setSubject: PropTypes.func.isRequired,
  subjectFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      data: PropTypes.any,
      name: PropTypes.string.isRequired,
      type: PropTypes.number,
    })).isRequired,
  })).isRequired,
};

const componentStyles = (theme) => ({
  mainContent: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "250px",
    },
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      marginTop: "4rem",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  mainRoot: {
    minHeight: "calc(100vh - 132px);",
    maxWidth: "100% !important",
  },
});

export default componentStyles;

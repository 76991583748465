import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardContent, Grid, MenuItem, TextField} from '@mui/material';
import {Create, RecentActors} from '@material-ui/icons';
import AllSubjectContacts from 'views/Dialer/Dialogs/AllSubjectContacts';
import {useIntl} from 'react-intl';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from 'assets/theme/components/dialer-subject';
import themeColors from 'assets/theme/colors';

const useStyles = makeStyles(componentStyles);

export default function SubjectContacts({
                                          subjectContact,
                                          updateSubjectContact,
                                          selectSubjectContact,
                                          contacts,
                                          departments,
                                          isCreatingNewContact
                                        }) {
  const classes = useStyles();
  const intl = useIntl();
  const nameRef = useRef();
  const [showAllContacts, setShowAllContacts] = useState(false);

  return <Card elevation={4} style={{backgroundColor: themeColors.white.main}}>
    <CardContent style={{padding: 12}}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
              classes={{root: classes.textField}}
            fullWidth
            required
            size="small"
            disabled={!subjectContact}
            value={subjectContact?.name ? subjectContact.name : ''}
            id="name"
            inputRef={nameRef}
            label={intl.formatMessage({id: "dialer.cards.subject_contact.label.name", defaultMessage: "Name"})}
            variant="outlined"
            onChange={e => updateSubjectContact('name', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
              classes={{root: classes.textField}}
            fullWidth
            size="small"
            disabled={!subjectContact}
            value={subjectContact?.email ? subjectContact.email : ''}
            id="email"
            label={intl.formatMessage({id: "dialer.cards.subject_contact.label.email", defaultMessage: "Email"})}
            variant="outlined"
            onChange={e => updateSubjectContact('email', e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
              classes={{root: classes.textField}}
              fullWidth
              size="small"
              disabled={!subjectContact}
              value={subjectContact?.phone ? subjectContact.phone : ''}
              id="phone"
              label={intl.formatMessage({id: "dialer.cards.subject_contact.label.phone", defaultMessage: "Phone"})}
              variant="outlined"
              onChange={e => updateSubjectContact('phone', e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
              classes={{root: classes.textField}}
              fullWidth
              size="small"
              disabled={!subjectContact}
              value={subjectContact?.mobile ? subjectContact.mobile : ''}
              id="mobile"
              label={intl.formatMessage({id: "dialer.cards.subject_contact.label.mobile", defaultMessage: "Mobile"})}
              variant="outlined"
              onChange={e => updateSubjectContact('mobile', e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
              classes={{root: classes.textField}}
              fullWidth
              size="small"
              disabled={!subjectContact}
              value={subjectContact?.title ? subjectContact.title : ''}
              id="title"
              label={intl.formatMessage({id: "dialer.cards.subject_contact.label.title", defaultMessage: "Title"})}
              variant="outlined"
              onChange={e => updateSubjectContact('title', e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
              classes={{root: classes.textField}}
            fullWidth
            size="small"
            select
            label={intl.formatMessage({
              id: "dialer.cards.subject_contact.label.department",
              defaultMessage: "Department"
            })}
            disabled={!subjectContact}
            id="department_id"
            value={subjectContact && subjectContact.department_id ? subjectContact.department_id : ''}
            onChange={e => updateSubjectContact('department_id', e.target.value)}
          >
            <MenuItem key={'department-select-none'} value=""><em>{intl.formatMessage({
              id: "common.select.none",
              defaultMessage: "None"
            })}</em></MenuItem>
            {departments.map(department =>
              <MenuItem key={'department-select-' + department.id} value={department.id}>{department.name}</MenuItem>
            )}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={contacts.length < 1}
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={() => setShowAllContacts(true)}
            startIcon={<RecentActors/>}
          >
            {intl.formatMessage({
              id: "dialer.cards.subject_contact.button.show_contacts",
              defaultMessage: "Show {count} contact(s)"
            }, {count: contacts.length})}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            startIcon={isCreatingNewContact ? <CircularProgress/> : <Create/>}
            onClick={() => {
              updateSubjectContact('create').then(() => {
                nameRef.current.focus();
                nameRef.current.select();
              });
            }}
            disabled={isCreatingNewContact}
          >
            {intl.formatMessage({
              id: "dialer.cards.subject_contact.button.create_new",
              defaultMessage: "Create new contact"
            })}
          </Button>
        </Grid>
      </Grid>
      {showAllContacts && <AllSubjectContacts
        open={showAllContacts}
        onClose={() => setShowAllContacts(false)}
        contacts={contacts}
        departments={departments}
        selectedContact={subjectContact}
        setSelectedContact={selectSubjectContact}
      />}
    </CardContent>
  </Card>;
}

SubjectContacts.propTypes = {
  subjectContact: PropTypes.object,
  selectSubjectContact: PropTypes.func.isRequired,
  updateSubjectContact: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
};

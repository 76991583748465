import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  lighten,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Add,
  Assessment,
  Business,
  CallToAction,
  Close,
  CloudDownload,
  CloudUpload,
  Group,
  Mail,
  SelectAll
} from '@material-ui/icons';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import themeColors from 'assets/theme/colors';
import theme from 'assets/theme/theme';
import hexToRgb from 'assets/theme/hex-to-rgb';
import {graphQLApi} from 'services/GraphQLApi';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import {assignmentSubjectColumns, subjectColumns} from 'variables/subjects';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import {localeTexts} from 'variables/datagrid';
import AssignmentSubjectEdit from 'views/Assignment/Subjects/AssignmentSubjectEdit';
import {makeStyles} from '@material-ui/styles';
import SendMail from 'views/Assignment/Dialogs/SendMail';
import {default as SendSubjectMail} from 'views/Dialer/Dialogs/SendMail';
import SubjectsFilter from 'components/SubjectsFilter';
import {rolePriorities} from 'config';
import moment from 'moment/moment';

const topAlignedDialogStyle = makeStyles({
  container: {
    alignItems: 'flex-start',
  },
});

export default function AssignmentSubjectList(props) {
  const intl = useIntl();
  const locationSearch = new URLSearchParams(props.location.search);
  const topAlignedDialogClasses = topAlignedDialogStyle();
  let assignmentId = props.match.params && props.match.params.assignmentID;
  /**
   *  Outcomes filter handling
   **/
  const [notification, setNotification] = useState('');
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [foundIds, setFoundIds] = useState([]);
  const [editAssignmentSubject, setEditAssignmentSubject] = useState(0);

  const [columns, setColumns] = useState([
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.subject.name', defaultMessage: 'Subject'}),
      field: 'subject_name',
      // type: 'string',
      // filtering: false,
      width: 200,
    },
    {
      headerName: intl.formatMessage(
          {id: 'assignment_subjects.list.column.subject.main_phone', defaultMessage: 'Main Phone'}),
      field: 'subject_main_phone',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage(
          {id: 'assignment_subjects.list.column.subject.address', defaultMessage: 'Address'}),
      field: 'subject_address_1',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.subject.zip', defaultMessage: 'Zip'}),
      field: 'subject_zip',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.subject.city', defaultMessage: 'City'}),
      field: 'subject_city',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.subject.email', defaultMessage: 'Email'}),
      field: 'subject_email',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage(
          {id: 'assignment_subjects.list.column.subject.website', defaultMessage: 'Website'}),
      field: 'subject_website',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage(
          {id: 'assignment_subjects.list.column.subject.registration_number', defaultMessage: 'Registration number'}),
      field: 'subject_registration_number',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage(
          {id: 'assignment_subjects.list.column.subject.business_type', defaultMessage: 'Business type'}),
      field: 'subject_business_type',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage(
          {id: 'assignment_subjects.list.column.subject.employees', defaultMessage: 'Employees'}),
      field: 'subject_employees',
      // type: 'string',
      // filtering: false,
      width: 100,
    },
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.outcome.name', defaultMessage: 'Outcome'}),
      field: 'outcome_name',
      // type: 'string',
      // filtering: false,
      width: 200,
    },
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.status.name', defaultMessage: 'Status'}),
      field: 'status_name',
      // type: 'string',
      // filtering: false,
      // width: 'auto'
    },
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.phoner.name', defaultMessage: 'Phoner'}),
      field: 'phoner_name',
      // type: 'string',
      // filtering: false,
      width: 200,
    },
    {
      headerName: intl.formatMessage({id: 'assignment_subjects.list.column.calls_made', defaultMessage: 'Calls made'}),
      field: 'calls_made',
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.next_contact_at',
        defaultMessage: 'Next contact at',
      }),
      field: 'next_contact_at',
      type: 'dateTime',
      width: 245,
      valueFormatter: ({ value }) => value && moment(value).format(intl.formatMessage({id:"common.datetime.format"})),
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.meeting_established_at',
        defaultMessage: 'Meeting established at',
      }),
      field: 'meeting_established_at',
      type: 'dateTime',
      width: 245,
      valueFormatter: ({ value }) => value && moment(value).format(intl.formatMessage({id:"common.datetime.format"})),
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.subject_contact.name',
        defaultMessage: 'Contact',
      }),
      field: 'subject_contact_name',
      // type: 'string',
      // filtering: false,
      width: 200,
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.subject_contact.title',
        defaultMessage: 'Contact title',
      }),
      field: 'subject_contact_title',
      width: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.subject_contact.phone',
        defaultMessage: 'Contact phone',
      }),
      field: 'subject_contact_phone',
      width: 200,
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.subject_contact.email',
        defaultMessage: 'Contact email',
      }),
      field: 'subject_contact_email',
      width: 200,
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.customer_contact.name',
        defaultMessage: 'Consultant',
      }),
      field: 'customer_contact_name',
      // type: 'string',
      // filtering: false,
      width: 200,
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.last_edited_by_manager_at',
        defaultMessage: 'Edited by manager',
      }),
      field: 'last_edited_by_manager_at',
      type: 'dateTime',
      width: 245,
      valueFormatter: ({ value }) => value && moment(value).format(intl.formatMessage({id:"common.datetime.format"})),
    },
    {
      headerName: intl.formatMessage({
        id: 'assignment_subjects.list.column.last_edited_by_phoner_at',
        defaultMessage: 'Edited by phoner',
      }),
      field: 'last_edited_by_phoner_at',
      type: 'dateTime',
      width: 245,
      valueFormatter: ({ value }) => value && moment(value).format(intl.formatMessage({id:"common.datetime.format"})),
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   width: 50,
    //   getActions: ({id}) => [
    //     <GridActionsCellItem icon={<Edit/>} label="Edit" onClick={() => {
    //       setEditAssignmentSubject(id);
    //       // props.history.push('/assignment/' + assignmentId + '/subjects/' + id);
    //     }}/>,
    //   ],
    // },
  ]);
  const [emailSettings, setEmailSettings] = useState({});
  const [assignment, setAssignment] = useState({});
  const [outcomes, setOutcomes] = useState([]);

  const [showExportFieldSelect, setShowExportFieldSelect] = useState(0);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [exportColumns, setExportColumns] = useState( []);

  /**
   * Initial filter information load from API
   **/
  let timeout = useRef();
  const debounce = (func, wait, immediate) => {
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout.current = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      let callNow = immediate && !timeout.current;
      // console.log('Debouncing', callNow, timeout.current, immediate, args);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  const debouncedUpdateResults = debounce((filter) => {
    updateSearchResults(filter);
  }, 1250);

  const tableRef = useGridApiRef();
  const [filter, setFilter] = useState('assignment_id:' + assignmentId);
  const [filterOptions, setFilterOptions] = useState({
    phoner_id: [],
    customer_contact_id: [],
    status_id: [],
    outcome_id: [],
    past_status_id: [],
    field: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const client = new graphQLApi(useAuthDispatch(), props.history);
  useEffect(() => {
    if (locationSearch.has('created_at')) {
      props.history.replace(window.location.pathname);
    }
    setIsLoading(true);
    client.query('{' +
        'assignmentOutcomes(filter:{assignment_id:' + assignmentId + '}){data{outcome{id name is_closed is_target}}}' +
        'assignmentSubjectStatuses{data{id name}}' +
        'assignmentSubjectFields(filter:{assignment_id:' + assignmentId +
        '},sorting:"name",direction:"asc"){data{id name}}' +
        'assignmentQuestions(filter:{assignment_id:' + assignmentId +
        '},sorting:"sorting",direction:"asc"){data{id text}}' +
        'users(filter:{id:'+authUser().id+'}) {data{settings}}' +
        'assignments(filter:{id:' + assignmentId + '}){data{' +
        ' name' +
        ' email_settings{confirmation_template_id direct_template_id alternative_1_template_id alternative_2_template_id report_template report_receiver_email}' +
        ' phoners{id name email}' +
        ' consultants{id name}' +
        ' outcomes{id name is_closed}' +
        '}}' +
        '}').then(r => {
      setIsLoading(false);
      if (!r) {
        return;
      }
      let newOptions = {...filterOptions};
      if (r.assignmentOutcomes) {
        setOutcomes(r.assignmentOutcomes.data.map(ao => ao.outcome));
      }
      let cols = [];
      if (r.assignmentSubjectFields) {
        newOptions.field = r.assignmentSubjectFields.data;
        let sort = 0;
        cols = assignmentSubjectColumns(intl, sort);
        cols = [...cols, ...subjectColumns(intl, cols.length, 'subjects.')];
        cols = [...cols, ...r.assignmentSubjectFields.data.map((c, i) => ({...c, sorting: i + cols.length}))];

        setColumns([...columns, ...r.assignmentSubjectFields.data.map(ef => ({
          headerName: ef.name,
          field: 'subject_field_data_' + ef.id,
        }))]);
      }
      if (r.assignmentQuestions) {
        cols = [
          ...cols,
          ...r.assignmentQuestions.data.filter(q => q.text !== '')
              .map((q, i) => ({id: 'Q' + q.id, name: 'Q'+(i+1)+': '+q.text, sorting: i + cols.length}))];
      }
      let savedColumns = [];
      if (r.users && r.users.data[0].settings) {
        savedColumns = JSON.parse(r.users.data[0].settings)?.export_columns?.[assignmentId]
        if (!savedColumns) {
          savedColumns = []
        }
      }
      if (Array.isArray(savedColumns)) {
        let expCols = [];
        savedColumns.forEach(sc => {
          let i = cols.findIndex(c => sc.id === c.id);
          if (i !== -1) {
            expCols.push(cols[i]);
            cols.splice(i, 1);
          }
        });
        setExportColumns(expCols);
        setAvailableColumns(cols);
      }
      else {
        setAvailableColumns(cols);
      }
      if (r.assignmentSubjectStatuses) {
        newOptions.status_id = r.assignmentSubjectStatuses.data;
        newOptions.past_status_id = r.assignmentSubjectStatuses.data;
      }
      if (r.assignments && r.assignments.data) {
        setAssignment({id: assignmentId, name: r.assignments.data[0].name});
        setEmailSettings(r.assignments.data[0].email_settings);
        newOptions.phoner_id = r.assignments.data[0].phoners ? r.assignments.data[0].phoners : [];
        newOptions.customer_contact_id = r.assignments.data[0].consultants ? r.assignments.data[0].consultants : [];
        newOptions.outcome_id = r.assignments.data[0].outcomes ? r.assignments.data[0].outcomes : [];
      }
      setFilterOptions(newOptions);
    });
  }, []);

  const [showTrashed, setShowTrashed] = useState(false);
  useEffect(() => {
    let cols = [...columns];
    if (showTrashed) {
      cols.push({
        headerName: intl.formatMessage({
          id: 'assignment_subjects.list.column.deleted_at',
          defaultMessage: 'Removed at',
        }),
        field: 'deleted_at',
        type: 'dateTime',
        width: 245,
        valueFormatter: ({ value }) => value && moment(value).format(intl.formatMessage({id:"common.datetime.format"})),
      });
    }
    else {
      cols = cols.filter(c => c.field !== 'deleted_at');
    }
    setColumns(cols);
    updateSearchResults(filter);
    if (rows.length) {
      fetchRows();
    }
  }, [showTrashed]);
  const handleRestoreSubjects = () => {
    const ids = selected.length ? selected : foundIds;
    client.mutate('{assignmentSubjectsRestore(ids:[' + ids.join(',') + '])}').then(r => {
      if (r.assignmentSubjectsRestore) {
        setSelected([]);
        updateSearchResults(filter);
        if (rows.length) {
          fetchRows();
        }
      }
    });
  }

  const updateSearchResults = (filter) => {
    setIsLoading(true);
    client.query('{' +
        'assignmentSubjects(limit:100000,filter:{' + filter + '},trashed:'+showTrashed+')' +
        '{total data{id}}' +
        '}').then(r => {
      setIsLoading(false);
      if (r && r.hasOwnProperty('assignmentSubjects')) {
        setTotal(r.assignmentSubjects.total);
      }
      setFoundIds(r.assignmentSubjects.data.map(as => as.id));
    });
  };

  const fetchRows = () => {
    setDataIsLoading(true);
    setRows([]);
    client.query('{' +
        'assignmentSubjects(filter:{' + filter + '},trashed:'+showTrashed+')' +
        ' {total data{' +
        '   id calls_made next_contact_at last_edited_by_manager_at last_edited_by_phoner_at meeting_established_at deleted_at' +
        '   subject{name main_phone address_1 zip city email website registration_number business_type employees subject_field_data{subject_field_id data}}' +
        '   outcome{name is_closed}' +
        '   phoner{id name email}' +
        '   customer_contact{name}' +
        '   status{id name}' +
        '   subject_contact{name title phone email department{name}}' +
        ' }}' +
        '}').then(r => {
      setDataIsLoading(false);
      if (r && r.hasOwnProperty('assignmentSubjects')) {
        setTotal(r.assignmentSubjects.total);
        setRows(r.assignmentSubjects.data.map(d => {
              for (let p1 in d) {
                if (typeof d[p1] === 'object') {
                  for (let p2 in d[p1]) {
                    if (typeof d[p1][p2] === 'object') {
                      if (p2 === "subject_field_data") {
                        for (let p3 in d[p1][p2]) {
                          d[p2 + '_' + d[p1][p2][p3].subject_field_id] = d[p1][p2][p3].data;
                        }
                      }
                      else {
                        for (let p3 in d[p1][p2]) {
                          d[p1 + '_' + p2 + '_' + p3] = d[p1][p2][p3];
                        }
                      }
                    }
                    else {
                      d[p1 + '_' + p2] = d[p1][p2];
                    }
                  }
                }
              }
              return d;
            },
        ));
      }
    });
  };

  const [downloadAnchor, setDownloadAnchor] = useState();
  const [sendAnchor, setSendAnchor] = useState();
  const [subjectsAnchor, setSubjectsAnchor] = useState();
  const [actionsAnchor, setActionsAnchor] = useState();

  const [associatedPhoners, setAssociatedPhoners] = useState({});
  const [associatedConsultants, setAssociatedConsultants] = useState({});
  const [showAssociatePhoner, setShowAssociatePhoner] = useState(false);
  const closeAssociationDialog = () => {
    setShowAssociatePhoner(false);
    setActionsAnchor(null);
    setAssociatedPhoners({});
  };
  const associatedPhonersCount = () => {
    let count = 0;
    for (let id in associatedPhoners) {
      count = count + parseInt(associatedPhoners[id]);
    }
    return count;
  }
  const handleAssociatePhoner = (id, amount) => {
    setAssociatedPhoners({...associatedPhoners, [id]: amount});
  };
  const handleAssociateConsultant = (id, amount) => {
    setAssociatedConsultants({...associatedConsultants, [id]: amount});
  };
  const updatePhonersAssociation = () => {
    setDataIsLoading(true);
    setIsLoading(true);
    closeAssociationDialog();
    let queries = [];
    let sliceStart = 0;
    const ids = selected.length ? selected : foundIds;
    for (let id in associatedPhoners) {
      let amount = parseInt(associatedPhoners[id]);
      if (!isNaN(amount) && amount > 0) {
        queries.push(
            'phoner_' + id + ':assignmentSubjectsUpdate(ids:[' + ids.slice(sliceStart, sliceStart + amount).join(',') +
            '] phoner_id:' + id + '){id phoner{id name}}');
        sliceStart = sliceStart + amount;
      }
    }
    sliceStart = 0;
    for (let id in associatedConsultants) {
      let amount = parseInt(associatedConsultants[id]);
      if (!isNaN(amount) && amount > 0) {
        queries.push('customer_contact_' + id + ':assignmentSubjectsUpdate(ids:[' +
            ids.slice(sliceStart, sliceStart + amount).join(',') + '] customer_contact_id:' + id +
            '){id customer_contact{id name}}');
        sliceStart = sliceStart + amount;
      }
    }
    if (queries.length === 0) {
      setDataIsLoading(false);
      setIsLoading(false);
      return;
    }
    client.mutate('{' + queries.join('\n') + '}').then(r => {
      setDataIsLoading(false);
      setIsLoading(false);
      let updateRows = [];
      if (r) {
        for (let k in r) {
          if (k.startsWith('phoner_')) {
            r[k].forEach(s => updateRows.push({...s, phoner_name: s.phoner ? s.phoner.name : ''}));
          }
          if (k.startsWith('customer_contact_')) {
            r[k].forEach(s => updateRows.push({
              ...s,
              customer_contact_name: s.customer_contact ? s.customer_contact.name : '',
            }));
          }
        }
      }
      if (rows.length && tableRef.current) {
        tableRef.current.updateRows(updateRows);
      }
    });
  };

  const [showCreateSubject, setShowCreateSubject] = useState(false);
  const [createSubject, setCreateSubject] = useState({id: assignmentId, name: '', phone: '', contact: ''});
  const [showAssignOutcome, setShowAssignOutcome] = useState(false);
  const [newOutcome, setNewOutcome] = useState();
  const [newStatus, setNewStatus] = useState();
  const handleSelectOutcome = () => {
    setDataIsLoading(true);
    setActionsAnchor(null);
    const ids = selected.length ? selected : foundIds;
    let updates = [];
    let rowUpdate = {};
    if (newOutcome) {
      updates.push('outcome_id:' + newOutcome);
      rowUpdate.outcome = filterOptions.outcome_id.find(o => o.id === newOutcome);
      rowUpdate.outcome_name = rowUpdate.outcome.name;
    }
    if (newStatus) {
      updates.push('status_id:' + newStatus);
      rowUpdate.status = filterOptions.status_id.find(s => s.id === newStatus);
      rowUpdate.status_name = rowUpdate.status.name;
    }
    client.mutate('{assignmentSubjectsUpdate(ids:[' + ids.join(',') + '] ' + updates.join(' ') + '){id}}').then(r => {
      if (r && r.assignmentSubjectsUpdate && rows.length) {
        tableRef.current.updateRows(r.assignmentSubjectsUpdate.map(as => ({id: as.id, ...rowUpdate})));
      }
      setDataIsLoading(false);
      setShowAssignOutcome(false);
    });
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleDeleteSubjects = (confirmed) => {
    setConfirmDelete(false);
    setSubjectsAnchor(null);
    const ids = selected.length ? selected : foundIds;
    if (confirmed) {
      client.mutate('{assignmentSubjectsDelete(ids:[' + ids.join(',') + '])}').then(r => {
        if (r.assignmentSubjectsDelete) {
          if (rows.length) {
            tableRef.current.updateRows(ids.map(s => ({id: s, _action: 'delete'})));
          }
          setSelected([]);
          updateSearchResults(filter);
        }
      });
    }
  };
  const ASSIGNMENT_DIALOG_HIDE = 0;
  const ASSIGNMENT_DIALOG_MODE_COPY = 1;
  const ASSIGNMENT_DIALOG_MODE_MOVE = 2;
  const [assignmentDialogMode, setAssignmentDialogMode] = useState(ASSIGNMENT_DIALOG_HIDE);
  const [assignmentDialogSelection, setAssignmentDialogSelection] = useState();
  const openAssignmentDialog = (mode) => {
    setAssignmentDialogSelection(null);
    setAssignmentDialogMode(mode);
  };
  const closeAssignmentDialog = () => {
    setAssignmentDialogMode(ASSIGNMENT_DIALOG_HIDE);
    setSubjectsAnchor(null);
  };
  const handleAssignmentDialogAction = () => {
    let mutation = '';
    closeAssignmentDialog();
    setDataIsLoading(true);
    const ids = selected.length ? selected : foundIds;
    switch (assignmentDialogMode) {
      case ASSIGNMENT_DIALOG_MODE_COPY:
        mutation = '{assignmentSubjectsUpdate(ids:[' + ids.join(',') + '], copy_to_assignment_id:' +
            assignmentDialogSelection.id + '){id}}';
        break;
      case ASSIGNMENT_DIALOG_MODE_MOVE:
        mutation = '{assignmentSubjectsUpdate(ids:[' + ids.join(',') + '], assignment_id:' +
            assignmentDialogSelection.id + '){id}}';
        break;
      default:
        return;
    }
    client.mutate(mutation).then(r => {
      if (r && r.assignmentSubjectsUpdate && assignmentDialogMode === ASSIGNMENT_DIALOG_MODE_MOVE) {
        if (rows.length) {
          tableRef.current.updateRows(r.assignmentSubjectsUpdate.map(s => ({id: s.id, _action: 'delete'})));
        }
        updateSearchResults(filter);
      }
      setDataIsLoading(false);
    });
  };

  const closeExportSubjectsDialog = () => {
    setShowExportFieldSelect(false);
    // setAvailableColumns([...availableColumns, ...exportColumns]);
  };

  const appendToExportedColumns = (column) => {
    const i = availableColumns.findIndex(c => c.id === column.id);
    const nEC = [...exportColumns];
    nEC.push(column);
    setExportColumns(nEC);
    const nAC = [...availableColumns];
    nAC.splice(i, 1);
    setAvailableColumns(nAC);
  };

  const returnToAvailableColumns = (column) => {
    const i = exportColumns.findIndex(c => c.id === column.id);
    const nAC = [...availableColumns];
    nAC.push(column);
    setAvailableColumns(nAC);
    const nEC = [...exportColumns];
    nEC.splice(i, 1);
    setExportColumns(nEC);
  };

  const handleSaveExportColumns = () => {
    let settings = {}
    settings.export_columns = {[assignmentId]: exportColumns}
    client.mutate('($userId:ID!, $settings:String) {userUpdate (id:$userId, settings:$settings) {id settings}}',
        {userId: authUser().id, settings: JSON.stringify(settings)}).then(response => {
      if (response && response.hasOwnProperty('userUpdate')) {
        const columns = JSON.parse(response.userUpdate.settings)['export_columns'][assignmentId]
        if (columns) setExportColumns(columns)
      }
    })
  }

  const [isDownloading, setIsDownloading] = useState(false);
  const handleSubjectsExport = () => {
    setIsDownloading(true);
    client.mutate('($subject_ids:[ID],$assignment_id:ID,$columns:[String])' +
        '{assignmentSubjectsDownload(subject_ids:$subject_ids,assignment_id:$assignment_id,columns:$columns)}',
        {
          assignment_id: assignmentId,
          subject_ids: selected.length ? selected : foundIds,
          columns: exportColumns.map(c => c.id),
        },
    ).then(r => {
      setIsDownloading(false);
      closeExportSubjectsDialog();
      if (r && r.assignmentSubjectsDownload) {
        setNotification(intl.formatMessage(
            {id: 'assignment_subjects.notification.export_queued', defaultMessage: 'Export job has been queued.'}));
      }
      else {
        setNotification(intl.formatMessage({
          id: 'assignment_subjects.notification.export_queue_failed',
          defaultMessage: 'Export job could not be queued!',
        }));
      }
    });
  };

  const handleDownloadReport = () => {
    setIsDownloading(true);
    client.mutate('($assignment_id:ID,$subject_ids:[ID])' +
        '{assignmentSubjectsReport(assignment_id:$assignment_id,assignment_subject_ids:$subject_ids)}',
        {
          assignment_id: assignmentId,
          subject_ids: selected.length ? selected : foundIds,
        },
    ).then(r => {
      setIsDownloading(false);
      setDownloadAnchor(null);
      if (r && r.assignmentSubjectsReport) {
        setNotification(intl.formatMessage(
            {id: 'assignment_subjects.notification.report_queued', defaultMessage: 'Report job has been queued.'}));
      }
      else {
        setNotification(intl.formatMessage({
          id: 'assignment_subjects.notification.report_queue_failed',
          defaultMessage: 'Report job could not be queued!',
        }));
      }
    });
  };

  const [assignmentSendType, setAssignmentSendType] = useState('');
//   const handleSendEmail = (type) => {
//     setIsLoading(true);
//     setSendAnchor(null);
//     setShowExportFieldSelect(0);
//     client.mutate('($assignment_id:ID,$subject_ids:[ID],$type:String!)' +
//         '{assignmentSubjectsSend(assignment_id:$assignment_id,assignment_subject_ids:$subject_ids,type:$type)}',
//         {
//           assignment_id: parseInt(assignmentId),
//           subject_ids: selected.length ? selected : foundIds,
//           type: type,
//         },
//     ).then(resp => {
//       setIsLoading(false);
//       if (resp && resp.assignmentSubjectsSend) {
// // todo maybe make a nice notification that a job has been scheduled to send the mails?
//       }
//     });
//   };

  const [selected, setSelected] = useState([]);
  const customToolbar = () => {
    return <GridToolbarContainer>
      <Button size="small" color="primary" startIcon={<SelectAll/>} onClick={() => {
        if (selected.length > 0) {
          setSelected([]);
        }
        else {
          setSelected(rows.map(r => r.id));
        }
      }}>
        {intl.formatMessage({
          id: 'assignment_subjects.datagrid.toolbar.button.select_all',
          defaultMessage: 'Select all/none',
        })}
      </Button>
      <GridToolbarColumnsButton/>
      <GridToolbarFilterButton/>
      <GridToolbarDensitySelector/>
    </GridToolbarContainer>;
  };
  const [sendMailTemplate, setSendMailTemplate] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const getSelectedSubject = () => {
    if (selected.length === 1) return rows.find(r => r.id === selected[0]);
    if (rows.length === 1) return rows[0];
    return null;
  }

  function handleShowingTrashed() {
    setShowTrashed(!showTrashed);
    setSubjectsAnchor(null);
  }

  return (
      <Grid container spacing={3} style={{display: 'flex', height: '100%'}}>
        <Grid item xs={12}>
          <Snackbar
              open={alertMessage.length > 0}
              anchorOrigin={{horizontal: 'center', vertical: 'top'}}
              onClose={() => setAlertMessage('')}>
            <SnackbarContent message={alertMessage} style={{backgroundColor: themeColors.error.light}}/>
          </Snackbar>
          {sendMailTemplate !== '' && !!getSelectedSubject() && <SendSubjectMail
              template={sendMailTemplate}
              getSubjectContactField={f => {
                let s = getSelectedSubject();
                if (s.subject_contact) return s.subject_contact[f];
                return s.subject[f];
              }}
              onClose={(_e, status) => {
                if (status === 'failed') {
                  setAlertMessage(
                      intl.formatMessage({
                        id: 'dialer.card.actions.send_mail.failed',
                        defaultMessage: 'The message was not send. Please check the email addresses and contents!',
                      }));
                }
                else {
                  setSendMailTemplate('');
                }
              }}
              assignmentSubject={getSelectedSubject()}
              assignmentId={assignmentId}
              history={props.history}
              open={sendMailTemplate !== ''}
          />}
          <Snackbar
              autoHideDuration={2500}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              onClose={() => setNotification('')}
              open={!!(notification && notification.length > 0)}
              message={notification}
          />
          <Paper>
            <ConfirmDialog
                onClose={handleDeleteSubjects}
                open={confirmDelete}
                title={intl.formatMessage(
                    {
                      id: 'assignment_subjects.dialog.confirm_deletion.title',
                      defaultMessage: 'Confirm deletion of {count} subjects',
                    },
                    {count: selected.length ? selected.length : total},
                )}
                message={intl.formatMessage({
                  id: 'assignment_subjects.dialog.confirm_deletion.message',
                  defaultMessage: 'Are you certain you want to remove the subjects from this assignment?',
                })}
            />
            <SubjectsFilter
                onChange={filter => {
                  setFilter(filter);
                  debouncedUpdateResults(filter);
                }}
                filter={['assignment_id:' + assignmentId]}
                filterOptions={filterOptions}
                outcomes={outcomes}/>
            <Grid container spacing={2} justifyContent="space-between" style={{margin: 0, width: '100%', padding: 4}}>
              <Grid item>
                <Grid container>
                  <Grid item style={{paddingTop: 4}}>
                    <Typography variant="h2">{intl.formatMessage({
                      id: 'assignment_subjects.list.table.heading',
                      defaultMessage: 'Search found {total} subjects',
                    }, {total: total})}</Typography>
                  </Grid>
                  <Grid item>
                    <Button disabled={dataIsLoading || isLoading} variant="contained" color="primary"
                            onClick={fetchRows}>
                      {intl.formatMessage({
                        id: 'assignment_subjects.filter.button.show_results',
                        defaultMessage: 'Show search results',
                      })}
                    </Button>
                  </Grid>
                  {isLoading && <Grid item>
                    <CircularProgress color="primary" size="2rem"/>
                  </Grid>}
                  {!authUser().isAllowed(rolePriorities.manager) && <Grid item>
                    <Button onClick={() => props.history.push('/dialer/' + assignmentId)}>{
                      intl.formatMessage({id:"assignment_subjects.list.button.to_phoner", defaultMessage:"To phoner"})
                    }</Button>
                  </Grid>}
                </Grid>
              </Grid>
              <Grid item>
                {authUser().isAllowed(rolePriorities.manager) && <Button style={{marginRight: 8}} startIcon={<CloudUpload/>}
                         onClick={() => props.history.push('/assignment/' + assignmentId + '/subjects/import')}>
                  {intl.formatMessage({
                    id: 'assignment_subjects.datagrid.toolbar.button.import',
                    defaultMessage: 'Import',
                  })}
                </Button>}
                <Button style={{marginRight: 8}} startIcon={<CloudDownload/>}
                        onClick={e => setDownloadAnchor(e.currentTarget)}>
                  {intl.formatMessage({
                    id: 'assignment_subjects.datagrid.toolbar.button.download',
                    defaultMessage: 'Download',
                  })}
                </Button>
                <Button style={{marginRight: 8}} startIcon={<Mail/>} onClick={e => setSendAnchor(e.currentTarget)}>
                  {intl.formatMessage({id: 'assignment_subjects.datagrid.toolbar.button.send', defaultMessage: 'Send'})}
                </Button>
                {authUser().isAllowed(rolePriorities.manager) && <Button style={{marginRight: 8}} startIcon={<Group/>} onClick={e => setSubjectsAnchor(e.currentTarget)}>
                  {intl.formatMessage({
                    id: 'assignment_subjects.datagrid.toolbar.button.subjects',
                    defaultMessage: 'Subjects',
                  })}
                </Button>}
                <Button style={{marginRight: 8}} startIcon={<CallToAction/>}
                         onClick={e => setActionsAnchor(e.currentTarget)}>
                  {intl.formatMessage({
                    id: 'assignment_subjects.datagrid.toolbar.button.actions',
                    defaultMessage: 'Actions',
                  })}
                </Button>
              </Grid>
            </Grid>
            <Menu id="tabel_menu_download" anchorEl={downloadAnchor} open={Boolean(downloadAnchor)}
                  onClose={() => setDownloadAnchor(null)}>
              <MenuItem onClick={() => {
                setShowExportFieldSelect(1);
                setDownloadAnchor(null);
              }}>{intl.formatMessage({
                id: 'assignment.subjects.selected_contexts.download.item.table',
                defaultMessage: 'Table view',
              })}</MenuItem>
              <MenuItem disabled={!emailSettings?.report_template} onClick={handleDownloadReport}>{intl.formatMessage({
                id: 'assignment.subjects.selected_contexts.download.item.report',
                defaultMessage: 'Report',
              })}</MenuItem>
            </Menu>
            {assignmentSendType !== '' && <SendMail
                onClose={() => {
                  setShowExportFieldSelect(0);
                  setSendAnchor(null);
                  setAssignmentSendType('');
                }}
                assignment={assignment}
                assignmentSubjectIds={selected.length ? selected : foundIds}
                columns={exportColumns.map(c => c.id)}
                history={props.history}
                type={assignmentSendType}
                open={true}
            />}
            <Menu id="tabel_menu_send" anchorEl={sendAnchor} open={Boolean(sendAnchor)}
                  onClose={() => setSendAnchor(null)}>
              <MenuItem
                  disabled={!emailSettings || !emailSettings.report_receiver_email}
                  onClick={() => {
                    setShowExportFieldSelect(2);
                    setSendAnchor(null);
                  }}>{intl.formatMessage({
                id: 'assignment.subjects.selected_contexts.download.item.table',
                defaultMessage: 'Table view',
              })}</MenuItem>
              <MenuItem
                  disabled={!emailSettings || !emailSettings.report_template === '' || !emailSettings.report_receiver_email}
                  onClick={() => setAssignmentSendType('report')}
              >{intl.formatMessage({
                id: 'assignment.subjects.selected_contexts.download.item.report',
                defaultMessage: 'Report',
              })}</MenuItem>
              <MenuItem disabled={true || !emailSettings || !emailSettings.report_receiver_email}>Kalender</MenuItem>
              <MenuItem disabled={!getSelectedSubject() || !emailSettings || !emailSettings.confirmation_template_id}
                        onClick={() => setSendMailTemplate('confirmation')}>Bekræftelse</MenuItem>
              <MenuItem disabled={!getSelectedSubject() || !emailSettings || !emailSettings.direct_template_id}
                        onClick={() => setSendMailTemplate('direct')}>Direkte</MenuItem>
              <MenuItem disabled={!getSelectedSubject() || !emailSettings || !emailSettings.alternative_1_template_id}
                        onClick={() => setSendMailTemplate('alternative_1')}>Alternativ 1</MenuItem>
              <MenuItem disabled={!getSelectedSubject() || !emailSettings || !emailSettings.alternative_2_template_id}
                        onClick={() => setSendMailTemplate('alternative_2')}>Alternativ 2</MenuItem>
            </Menu>
            <Menu id="tabel_menu_subjects" anchorEl={subjectsAnchor} open={Boolean(subjectsAnchor)}
                  onClose={() => setSubjectsAnchor(null)}>
              <MenuItem onClick={() => {
                if (showTrashed) {
                  handleRestoreSubjects();
                }
                else {
                  setConfirmDelete(true);
                }
                setSubjectsAnchor(null);
              }}>
                {showTrashed ?
                    intl.formatMessage({
                      id: 'assignment.subjects.selected_contexts.subjects.item.restore',
                      defaultMessage: 'Restore to assignment',
                    })
                    : intl.formatMessage({
                      id: 'assignment.subjects.selected_contexts.subjects.item.remove',
                      defaultMessage: 'Remove from assignment',
                    })
                }
              </MenuItem>
              <MenuItem onClick={() => openAssignmentDialog(ASSIGNMENT_DIALOG_MODE_COPY)}>
                {intl.formatMessage({
                  id: 'assignment.subjects.selected_contexts.subjects.item.copy',
                  defaultMessage: 'Copy to assignment',
                })}
              </MenuItem>
              <MenuItem onClick={() => openAssignmentDialog(ASSIGNMENT_DIALOG_MODE_MOVE)}>
                {intl.formatMessage({
                  id: 'assignment.subjects.selected_contexts.subjects.item.move',
                  defaultMessage: 'Move to assignment',
                })}
              </MenuItem>
              <MenuItem onClick={() => handleShowingTrashed()}>
                {showTrashed
                    ? intl.formatMessage({
                      id: 'assignment.subjects.selected_contexts.subjects.item.hide_trashed',
                      defaultMessage: 'Hide removed subjects',
                    })
                    : intl.formatMessage({
                      id: 'assignment.subjects.selected_contexts.subjects.item.show_trashed',
                      defaultMessage: 'Show removed subjects',
                    })}
              </MenuItem>
            </Menu>
            <Menu id="tabel_menu_actions" anchorEl={actionsAnchor} open={Boolean(actionsAnchor)}
                  onClose={() => setActionsAnchor(null)}>
              <MenuItem onClick={() => {
                setShowAssociatePhoner(true);
                setActionsAnchor(null);
              }}>
                {intl.formatMessage({
                  id: 'assignment.subjects.selected_contexts.actions.item.associate',
                  defaultMessage: 'Associate phoners/consultants',
                })}
              </MenuItem>
              <MenuItem onClick={() => {
                setShowAssignOutcome(true);
                setActionsAnchor(null);
              }}>
                {intl.formatMessage({
                  id: 'assignment.subjects.selected_contexts.actions.item.change',
                  defaultMessage: 'Change outcome/status',
                })}
              </MenuItem>
              <MenuItem onClick={() => {
                const ids = selected.length ? selected : foundIds;
                if (ids.length) {
                  localStorage.setItem('assignment_subject_ids', ids);
                  props.history.push('/dialer/' + assignmentId + '#edit_subjects');
                }
              }}>
                {intl.formatMessage({
                  id: 'assignment.subjects.selected_contexts.actions.item.edit',
                  defaultMessage: 'Edit subjects',
                })}
              </MenuItem>
              <MenuItem onClick={() => setShowCreateSubject(1)}>
                {intl.formatMessage({
                  id: 'assignment.subjects.selected_contexts.actions.item.create',
                  defaultMessage: 'Create subject',
                })}
              </MenuItem>
              {/*<MenuItem disabled>
              {intl.formatMessage({
                id: "assignment.subjects.selected_contexts.actions.item.show",
                defaultMessage: "Show duplicates"
              })}
            </MenuItem>
            <MenuItem disabled>
              {intl.formatMessage({
                id: "assignment.subjects.selected_contexts.actions.item.move",
                defaultMessage: "Move duplicates"
              })}
            </MenuItem>
            <MenuItem disabled>
              {intl.formatMessage({
                id: "assignment.subjects.selected_contexts.actions.item.sort_data",
                defaultMessage: "Sort field data"
              })}
            </MenuItem>*/}
            </Menu>
            {!!showCreateSubject &&
                <Dialog open={showCreateSubject > 0} maxWidth="xl" onClose={() => setShowCreateSubject(0)}
                        style={{overflow: 'auto'}}>
                  {showCreateSubject === 1
                      ? <EnhancedTable
                          {...props}
                          urlState={false}
                          title={intl.formatMessage(
                              {id: 'subjects.list.table.heading', defaultMessage: 'Subjects list'})}
                          columns={[
                            {
                              title: intl.formatMessage({id: 'subjects.list.column.name', defaultMessage: 'Name'}),
                              field: 'name',
                            },
                            {
                              title: intl.formatMessage(
                                  {id: 'subjects.list.column.address_1', defaultMessage: 'Address 1'}),
                              field: 'address_1',
                            },
                            {
                              title: intl.formatMessage({id: 'subjects.list.column.zip', defaultMessage: 'Zip'}),
                              field: 'zip',
                            },
                            {
                              title: intl.formatMessage({id: 'subjects.list.column.city', defaultMessage: 'City'}),
                              field: 'city',
                            },
                            {
                              title: intl.formatMessage(
                                  {id: 'subjects.list.column.main_phone', defaultMessage: 'Phone'}),
                              field: 'main_phone',
                            },
                            {
                              title: intl.formatMessage(
                                  {id: 'subjects.list.column.direct_phone', defaultMessage: 'Direct'}),
                              field: 'direct_phone',
                            },
                            {
                              title: intl.formatMessage(
                                  {id: 'subjects.list.column.mobile_phone', defaultMessage: 'Mobile'}),
                              field: 'mobile_phone',
                            },
                            {
                              title: intl.formatMessage({id: 'subjects.list.column.email', defaultMessage: 'Email'}),
                              field: 'email',
                              type: 'string',
                            },
                            {
                              title: intl.formatMessage(
                                  {id: 'subjects.list.column.contacts_count', defaultMessage: 'Contacts'}),
                              field: 'subject_contacts_count',
                              type: 'number',
                            },
                          ]}
                          query="subjects"
                          fields="id name address_1 zip city main_phone direct_phone mobile_phone email"
                          counts={['subject_contacts']}
                          icon={<Business/>}
                          rowsPerPageOptions={[]}
                          defaultRowsPerPage={20}
                          actions={[
                            {
                              icon: Add,
                              label: intl.formatMessage({
                                id: 'assignment.subjects.dialog.add_subjects',
                                defaultMessage: 'Add selected subjects to assignment',
                              }),
                              onSelected: true,
                              onClick: rows => {
                                client.mutate('{assignmentSubjectsCreate(' +
                                    ' assignment_id:' + assignmentId +
                                    ' subject_ids:[' + rows.map(r => r.id).join(',') + ']' +
                                    '){id}}').then(r => {
                                  if (r) {
                                    setActionsAnchor(null);
                                    setShowCreateSubject(0);
                                    updateSearchResults(filter);
                                  }
                                });
                              },
                            },
                            {
                              icon: Add,
                              tooltip: intl.formatMessage({
                                id: 'assignment.subjects.dialog.create_subject',
                                defaultMessage: 'Create a new subject',
                              }),
                              isFreeAction: true,
                              onClick: () => {
                                setShowCreateSubject(2);
                              },
                            },
                          ]}
                      />
                      : <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h2">
                              {intl.formatMessage({
                                id: 'assignment.subjects.dialog.heading.create_subject',
                                defaultMessage: 'Create a new subject and associate it with this assignment',
                              })}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage(
                                    {id: 'assignment.subjects.dialog.label.name', defaultMessage: 'Name'})}
                                value={createSubject.name}
                                onChange={e => setCreateSubject({...createSubject, name: e.target.value})}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage(
                                    {id: 'assignment.subjects.dialog.label.main_phone', defaultMessage: 'Main phone'})}
                                value={createSubject.phone}
                                onChange={e => setCreateSubject({...createSubject, phone: e.target.value})}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage(
                                    {id: 'assignment.subjects.dialog.label.contact_name', defaultMessage: 'Contact'})}
                                value={createSubject.contact}
                                onChange={e => setCreateSubject({...createSubject, contact: e.target.value})}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="flex-end">
                          <Grid item>
                            <Button variant="contained" onClick={() => setShowCreateSubject(1)}>{intl.formatMessage(
                                {id: 'common.button.cancel'})}</Button>
                          </Grid>
                          <Grid item>
                            <Button variant="contained" color="primary" onClick={() => {
                              client.mutate(
                                  '($id:ID!,$name:String,$phone:String'+(createSubject.contact ? ',$contact:String' : '')+') {assignmentSubjectCreate(' +
                                  'assignment_id:$id' +
                                  ' subject:{' +
                                  '  name:$name main_phone:$phone' +
                                  (createSubject.contact ? '  subject_contacts:[' +
                                      '   { name:$contact }' +
                                      '  ]' : '') +
                                  ' }' +
                                  ') {id}}', createSubject).then(r => {
                                if (r) {
                                  setActionsAnchor(null);
                                  setShowCreateSubject(0);
                                  updateSearchResults(filter);
                                  setCreateSubject({...createSubject, name: '', phone: '', contact: ''})
                                }
                              });
                            }}>{intl.formatMessage({id: 'common.button.save'})}</Button>
                          </Grid>
                        </Grid>
                      </DialogContent>}
                </Dialog>}
            <Dialog open={showAssociatePhoner} onClose={closeAssociationDialog} maxWidth="md">
              <DialogTitle disableTypography>
                <Typography variant="h2">
                  {intl.formatMessage({
                    id: 'assignment.subjects.dialog.change_phoner',
                    defaultMessage: 'Assign phoners and consultants to {count} subjects',
                  }, {
                    count: selected.length ? selected.length : total,
                  })}
                </Typography>
                <IconButton
                    style={{position: 'absolute', right: theme.spacing(1), top: theme.spacing(1)}}
                    onClick={closeAssociationDialog}
                >
                  <Close/>
                </IconButton>
              </DialogTitle>
              <DialogContent style={{overflow: 'clip'}}>
                <Grid container spacing={1} justifyContent="space-between">
                  <Grid item xs={6} style={{maxWidth: '49%'}}>
                    {intl.formatMessage({
                      id: 'assignment.subjects.dialog.change_phoner.enter_each_phoner_association',
                      defaultMessage: 'Enter the amount you wish to associate to each phoner.',
                    })}
                  </Grid>
                  <Grid item xs={6} style={{maxWidth: '49%'}}>
                    {intl.formatMessage({
                      id: 'assignment.subjects.dialog.change_phoner.enter_each_consultant_association',
                      defaultMessage: 'Enter the amount you wish to associate to each consultant.',
                    })}
                  </Grid>
                  <Grid item xs={6} style={{
                    maxWidth: '49%',
                    maxHeight: 300,
                    overflow: 'auto',
                    border: '1px solid ' + themeColors.gray['200'],
                    borderRadius: 4,
                  }}>
                    <Grid container spacing={1} key={'phoner_associate_none'}>
                      <Grid item xs={2}>
                        <Input
                            id={'phoner_none'}
                            type="number"
                            fullWidth
                            value={associatedPhoners.null ? associatedPhoners.null : '0'}
                            onChange={e => handleAssociatePhoner('null', e.target.value)}
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                updatePhonersAssociation();
                              }
                            }}
                            inputProps={{style: {textAlign: 'right', fontSize: 14}, min: 0}}
                            autoFocus
                        />
                      </Grid>
                      <Grid item xs={10} style={{paddingTop: 6}}>{intl.formatMessage({
                        id: 'assignment.subjects.dialog.change_phoner.no_association',
                        defaultMessage: 'None - Remove association',
                      })}</Grid>
                    </Grid>
                    {filterOptions.phoner_id.map((p, k) => <Grid container spacing={1} key={'phoner_associate_' + k}>
                      <Grid item xs={2}>
                        <Input
                            id={'phoner_' + p.id}
                            type="number"
                            fullWidth
                            value={associatedPhoners[p.id] ? associatedPhoners[p.id] : '0'}
                            onChange={e => handleAssociatePhoner(p.id, e.target.value)}
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                updatePhonersAssociation();
                              }
                            }}
                            inputProps={{style: {textAlign: 'right', fontSize: 14}, min: 0}}
                        />
                      </Grid>
                      <Grid item xs={10} style={{paddingTop: 6}}>{p.name}</Grid>
                    </Grid>)}
                  </Grid>
                  <Grid item xs={6} style={{
                    maxWidth: '49%',
                    maxHeight: 300,
                    overflow: 'auto',
                    border: '1px solid ' + themeColors.gray['200'],
                    borderRadius: 4,
                  }}>
                    <Grid container spacing={1} key={'customer_contact_none'}>
                      <Grid item xs={2}>
                        <Input
                            id={'customer_contact_none'}
                            type="number"
                            fullWidth
                            value={associatedConsultants.null ? associatedConsultants.null : '0'}
                            onChange={e => handleAssociateConsultant('null', e.target.value)}
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                updatePhonersAssociation();
                              }
                            }}
                            inputProps={{style: {textAlign: 'right', fontSize: 14}, min: 0}}
                        />
                      </Grid>
                      <Grid item xs={10} style={{paddingTop: 6}}>{intl.formatMessage({
                        id: 'assignment.subjects.dialog.change_phoner.no_association',
                        defaultMessage: 'None - Remove association',
                      })}</Grid>
                    </Grid>
                    {filterOptions.customer_contact_id.map(
                        (p, k) => <Grid container spacing={1} key={'customer_contact_' + k}>
                          <Grid item xs={2}>
                            <Input
                                id={'customer_contact_' + p.id}
                                type="number"
                                fullWidth
                                value={associatedConsultants[p.id] ? associatedConsultants[p.id] : '0'}
                                onChange={e => handleAssociateConsultant(p.id, e.target.value)}
                                onKeyUp={e => {
                                  if (e.key === 'Enter') {
                                    updatePhonersAssociation();
                                  }
                                }}
                                inputProps={{style: {textAlign: 'right', fontSize: 14}, min: 0}}
                            />
                          </Grid>
                          <Grid item xs={10} style={{paddingTop: 6}}>{p.name}</Grid>
                        </Grid>)}
                  </Grid>
                  <Grid item xs={6} style={{color:associatedPhonersCount() > (selected.length ? selected.length : total) ? themeColors.error.main : 'inherit'}}>
                    {intl.formatMessage({
                      id:"assignment.subjects.dialog.change_phoner.total_assigned_phoners",
                      defaultMessage:"You have assigned {count} out of {total} subjects."},{
                      count: associatedPhonersCount(),
                      total: selected.length ? selected.length : total,
                    })}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button color="primary" variant="outlined" onClick={closeAssociationDialog}>
                  {intl.formatMessage({id: 'common.button.close'})}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={updatePhonersAssociation}
                    disabled={associatedPhonersCount() > (selected.length ? selected.length : total)}
                >
                  {intl.formatMessage({id: 'common.button.update'})}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={showAssignOutcome} onClose={() => {
              setShowAssignOutcome(false);
              setActionsAnchor(null);
            }}>
              <DialogTitle disableTypography>
                <Typography variant="h2">
                  {intl.formatMessage({
                    id: 'assignment.subjects.dialog.change_outcome_status',
                    defaultMessage: 'Change outcome/status on {count} subjects',
                  }, {
                    count: selected.length ? selected.length : total,
                  })}
                </Typography>
                <IconButton
                    style={{position: 'absolute', right: theme.spacing(1), top: theme.spacing(1)}}
                    onClick={() => {
                      setShowAssignOutcome(false);
                      setActionsAnchor(null);
                    }}
                >
                  <Close/>
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth style={{
                      padding: 4,
                      backgroundColor: 'rgba(' + hexToRgb(themeColors.success.main) + ', 0.2)',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      borderRadius: 4,
                      borderColor: themeColors.gray[400],
                    }}>
                      <FormLabel style={{padding: 4, fontWeight: 'bold'}} component="legend">Åbne udfald</FormLabel>
                      <RadioGroup aria-label="outcome" name="outcome" value={newOutcome}
                                  onChange={e => setNewOutcome(e.target.value)}>
                        {filterOptions.outcome_id.filter(o => !o.is_closed).map((o, k) => <FormControlLabel
                            key={'outcomes-' + k}
                            value={o.id}
                            control={<Radio/>}
                            label={o.name}/>)}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth style={{
                      padding: 4,
                      backgroundColor: 'rgba(' + hexToRgb(themeColors.error.main) + ', 0.2)',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      borderRadius: 4,
                      borderColor: themeColors.gray[400],
                    }}>
                      <FormLabel style={{padding: 4, fontWeight: 'bold'}} component="legend">Lukkede udfald</FormLabel>
                      <RadioGroup aria-label="outcome" name="outcome" value={newOutcome}
                                  onChange={e => setNewOutcome(e.target.value)}>
                        {filterOptions.outcome_id
                                 .filter(o => o.is_closed)
                                 .map((o, k) => <FormControlLabel key={'outcomes-' + k}
                                                                  value={o.id}
                                                                  control={<Radio/>}
                                                                  label={o.name}/>)}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" style={{
                      padding: 4,
                      borderStyle: 'solid',
                      borderWidth: 2,
                      borderRadius: 4,
                      borderColor: themeColors.gray[400],
                    }}>
                      <FormLabel style={{padding: 4, fontWeight: 'bold'}} component="legend">Status</FormLabel>
                      <RadioGroup style={{flexFlow: 'row wrap'}} aria-label="status" name="status" value={newStatus}
                                  onChange={e => setNewStatus(e.target.value)}>
                        {filterOptions.status_id.map((s, k) => <FormControlLabel style={{width: '30%'}} key={'outcomes-' + k}
                                                                           value={s.id} control={<Radio/>}
                                                                           label={s.name}/>)}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item container xs={12} justifyContent="space-between">
                    <Button variant="outlined" color="primary" onClick={() => {
                      setNewOutcome(null);
                      setNewStatus(null);
                    }}>Nulstil</Button>
                    <Button variant="contained" color="primary" onClick={handleSelectOutcome}>Udfør</Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
            <Dialog open={assignmentDialogMode > 0} onClose={closeAssignmentDialog} maxWidth={'lg'}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {assignmentDialogMode > 0 && <EnhancedTable
                        icon={<Assessment/>}
                        title={assignmentDialogMode === ASSIGNMENT_DIALOG_MODE_COPY
                            ? intl.formatMessage({
                              id: 'assignment.subjects.dialog.copy_subjects',
                              defaultMessage: 'Copy {count} subjects to assignment',
                            }, {
                              count: selected.length ? selected.length : total,
                            })
                            : intl.formatMessage({
                              id: 'assignment.subjects.dialog.move_subjects',
                              defaultMessage: 'Move {count} subjects to assignment',
                            }, {
                              count: selected.length ? selected.length : total,
                            })}
                        query={'assignments'}
                        fields={'id name customer{name} manager{name}'}
                        counts={['subjects']}
                        filter={'is_active:true,is_template:false'}
                        columns={[
                          {
                            title: intl.formatMessage({id: 'assignments.list.column.name', defaultMessage: 'Name'}),
                            field: 'name',
                          },
                          {
                            title: intl.formatMessage({
                              id: 'assignments.list.column.customer.name',
                              defaultMessage: 'Customer',
                            }),
                            field: 'customer.name',
                            type: 'string',
                            filtering: false,
                            width: 'auto',
                          },
                          {
                            title: intl.formatMessage({
                              id: 'assignments.list.column.manager.name',
                              defaultMessage: 'Manager',
                            }),
                            field: 'manager.name',
                            type: 'string',
                            filtering: false,
                            width: 'auto',
                          },
                          {
                            title: intl.formatMessage({
                              id: 'assignments.list.column.subjects_count',
                              defaultMessage: 'Subjects',
                            }),
                            field: 'subjects_count',
                            sortable: false,
                          },
                        ]}
                        actions={[
                          {tooltip: 'Vælg opgave', rowClick: true, onClick: row => setAssignmentDialogSelection(row)},
                        ]}
                        rowStyle={row => (
                            {
                              backgroundColor: (assignmentDialogSelection && assignmentDialogSelection.id === row.id)
                                  ? 'rgba(' + hexToRgb(themeColors.primary.main) + ', 0.2)'
                                  : 'inherit',
                            }
                        )}
                    />}
                  </Grid>
                  <Grid item container xs={12} justifyContent="space-between">
                    <Button variant="outlined" color="primary"
                            onClick={closeAssignmentDialog}>{intl.formatMessage({id: 'common.button.close'})}</Button>
                    <Button variant="contained" color="primary"
                            onClick={handleAssignmentDialogAction}>{intl.formatMessage(
                        {id: 'common.button.save'})}</Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
            <Dialog open={showExportFieldSelect > 0} onClose={closeExportSubjectsDialog}>
              <DialogTitle disableTypography>
                <Typography variant="h2">Vælg de kolonner du vil have med i eksporten</Typography>
                <IconButton
                    style={{position: 'absolute', right: theme.spacing(1), top: theme.spacing(1)}}
                    onClick={closeExportSubjectsDialog}>
                  <Close/>
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {isDownloading ? <Grid container item xs={12} justifyContent="center"><CircularProgress/></Grid> :
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <InputLabel shrink>Tilgængelige kolonner</InputLabel>
                        <Paper style={{height: 400, overflow: 'auto'}}>
                          <List dense component="div" role="list">
                            {availableColumns.sort((a, b) => {
                              if (a.sorting > b.sorting) {
                                return 1;
                              }
                              if (a.sorting < b.sorting) {
                                return -1;
                              }
                              return 0;
                            }).map((column) => {
                              const labelId = `transfer-list-item-${column.id}-label`;
                              return (
                                  <ListItem key={column.id} role="listitem" button
                                            onClick={() => appendToExportedColumns(column)}>
                                    <ListItemText id={labelId} primary={column.name}/>
                                  </ListItem>
                              );
                            })}
                          </List>
                        </Paper>
                      </Grid>
                      <Grid item container xs={2} justifyContent="space-around" alignItems="center" direction="column"
                            wrap="nowrap">
                        <IconButton onClick={() => {
                          setAvailableColumns([...exportColumns, ...availableColumns]);
                          setExportColumns([]);
                        }}><FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon></IconButton>
                        <IconButton onClick={() => {
                          setExportColumns([...exportColumns, ...availableColumns]);
                          setAvailableColumns([]);
                        }}><FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon></IconButton>
                      </Grid>
                      <Grid item xs={5}>
                        <InputLabel shrink>Eksporterede kolonner</InputLabel>
                        <Paper style={{height: 400, overflow: 'auto'}}>
                          <List dense component="div" role="list">
                            {exportColumns.map((column) => {
                              const labelId = `transfer-list-item-${column.id}-label`;
                              return (
                                  <ListItem key={column.id} role="listitem" button
                                            onClick={() => returnToAvailableColumns(column)}>
                                    <ListItemText id={labelId} primary={column.name}/>
                                  </ListItem>
                              );
                            })}
                          </List>
                        </Paper>
                      </Grid>
                      <Grid container item xs={12} justifyContent="space-between">
                        <Button variant="outlined" onClick={() => handleSaveExportColumns()}>{
                          intl.formatMessage({id:"assignment_subjects.download.button.save_columns", defaultMessage:"Save columns"})
                        }</Button>
                        <Button disabled={exportColumns.length < 1} variant="contained" color="primary"
                                onClick={() => {
                                  if (showExportFieldSelect === 1) {
                                    handleSubjectsExport();
                                  }
                                  else {
                                    setAssignmentSendType('table');
                                  }
                                }}>
                          {showExportFieldSelect === 1 ? intl.formatMessage({
                            id: 'assignment_subjects.download.button.export_subjects',
                            defaultMessage: 'Export subjects',
                          }) : intl.formatMessage({
                            id: 'assignment_subjects.download.button.send_subjects',
                            defaultMessage: 'Send subjects',
                          })}
                        </Button>
                      </Grid>
                    </Grid>}
              </DialogContent>
            </Dialog>
          </Paper>
        </Grid>
        <Grid item xs={12} style={{height: 'calc(100vh - 475px)'}}>
          <DataGridPro
              apiRef={tableRef}
              sx={{
                boxShadow: 1,
                border: 1,
                borderColor: themeColors.gray[200],
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: `1px solid ${themeColors.gray[200]}`,
                },
                '& .MuiDataGrid-columnHeader': {
                  borderRightWidth: 0,
                },
                '& .MuiDataGrid-cell': {
                  borderRight: `1px solid ${themeColors.gray[200]}`,
                },
                '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                  borderBottom: `1px solid ${themeColors.gray[200]}`,
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: `1px solid ${themeColors.gray[200]}`,
                },
                '& .MuiDataGrid-columnSeparator': {
                  color: themeColors.gray[200],
                },
                '& .row-marked': {
                  backgroundColor: lighten(themeColors.error.main, 0.8),
                  '&.Mui-hovered': {
                    backgroundColor: lighten(themeColors.error.main, 0.7),
                  },
                },
              }}
              components={{Toolbar: customToolbar}}
              disableMultipleSelection={false}
              columns={columns}
              rows={rows}
              disableColumnMenu
              loading={dataIsLoading}
              selectionModel={selected}
              onSelectionModelChange={setSelected}
              onRowDoubleClick={params => {
                localStorage.setItem('assignment_subject_ids', [params.id]);
                props.history.push('/dialer/' + assignmentId + '#edit_subjects');
              }}
              rowHeight={27}
              headerHeight={35}
              pageSize={20000}
              localeText={localeTexts(intl)}
              pinnedColumns={{
                left: [columns[0].field],
                // right: ['actions'],
              }}
              getRowClassName={({row}) => (row.outcome?.is_closed || row.status?.id === "4") ? 'row-marked' : ''}
          />
          <Dialog open={editAssignmentSubject > 0} maxWidth="lg" onClose={() => setEditAssignmentSubject(0)}
                  classes={topAlignedDialogClasses}>
            {editAssignmentSubject > 0 &&
                <AssignmentSubjectEdit {...props} id={editAssignmentSubject} assignmentId={assignmentId}
                                       onSave={() => setEditAssignmentSubject(0)}/>}
          </Dialog>
        </Grid>
      </Grid>
  )
}
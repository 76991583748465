import {Assignment, DashboardSharp, ListAlt, Mail, People, Phone, Score, Settings, Sms} from '@material-ui/icons';
import AssignmentEdit from 'views/Admin/Assignments/AssignmentEdit';
import Dashboard from 'views/Assignment/Dashboard';
import AssignmentEmailSettingEdit from 'views/Assignment/Email/AssignmentEmailSettingEdit';
import AssignmentOutcomeEdit from 'views/Assignment/Outcomes/AssignmentOutcomeEdit';
import AssignmentOutcomeList from 'views/Assignment/Outcomes/AssignmentOutcomeList';
import AssignmentSubjectEdit from 'views/Assignment/Subjects/AssignmentSubjectEdit';
import AssignmentSubjectList from 'views/Assignment/Subjects/AssignmentSubjectList';
import {rolePriorities} from '../config';
import SubjectFieldsEdit from 'views/Assignment/SubjectFields/SubjectFieldsEdit';
import SubjectFieldsList from 'views/Assignment/SubjectFields/SubjectFieldsList';
import SubjectContactEdit from 'views/Admin/Subjects/SubjectContactEdit';
import AssignmentQuestionList from 'views/Assignment/Questions/AssignmentQuestionList';
import AssignmentQuestionEdit from 'views/Assignment/Questions/AssignmentQuestionEdit';
import ImportFile from '../views/Assignment/Import/ImportFile';
import MailsList from '../views/Assignment/Logs/MailsList';
import SmsList from 'views/Assignment/Logs/SmsList';

export default function routes(intl, prefix) {
  const routes = [
    {
      path: "",
      exact: true,
      name: intl.formatMessage({id: "menu.assignment.dashboard", defaultMessage: "Dashboard"}),
      icon: DashboardSharp,
      component: Dashboard,
      query: 'assignments(filter:{id:#assignmentID#}) {data{name customer{name}}}',
      queryResult: (result) => {
        return result.assignments.data[0].name;
      }
    },
    {
      path: "/subjects",
      name: intl.formatMessage({id: "menu.assignment_subjects.list", defaultMessage: "Subjects"}),
      icon: People,
      component: AssignmentSubjectList,
      allow_priority: rolePriorities.manager,
      views: [
        {
          path: "/import",
          name: intl.formatMessage({id: "menu.import", defaultMessage: "Import"}),
          component: ImportFile,
          hidden: true,
        },
        {
          path: "/:subjectId/contact/create",
          name: intl.formatMessage({id: "menu.subject_contacts.create", defaultMessage: "Create Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
        {
          path: "/:subjectId/contact/:id",
          name: intl.formatMessage({id: "menu.subject_contacts.edit", defaultMessage: "Edit Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.assignment_subjects.create", defaultMessage: "Create Subject"}),
          component: AssignmentSubjectEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.assignment_subjects.edit", defaultMessage: "Edit Subject"}),
          component: AssignmentSubjectEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/settings",
      exact: true,
      name: intl.formatMessage({id: "menu.assignment.settings", defaultMessage: "Settings"}),
      icon: Settings,
      allow_priority: rolePriorities.manager,
      views: [
        {
          path: "/assignment",
          icon: Assignment,
          name: intl.formatMessage({id: "menu.assignment.settings.generel", defaultMessage: "Generel"}),
          component: AssignmentEdit,
        },
        {
          path: "/email",
          icon: Mail,
          name: intl.formatMessage({id: "menu.assignment.settings.email", defaultMessage: "Email"}),
          component: AssignmentEmailSettingEdit,
        },
        {
          path: "/outcomes",
          name: intl.formatMessage({id: "menu.assignment_outcomes.list", defaultMessage: "Outcomes"}),
          icon: Score,
          component: AssignmentOutcomeList,
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.assignment_outcomes.create", defaultMessage: "Create Outcome"}),
              component: AssignmentOutcomeEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.assignment_outcomes.edit", defaultMessage: "Edit Outcome"}),
              component: AssignmentOutcomeEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/fields",
          icon: ListAlt,
          name: intl.formatMessage({id: "menu.assignment.settings.fields.list", defaultMessage: "Fields"}),
          component: SubjectFieldsList,
          views: [
            {
              path: "/create",
              name: intl.formatMessage({
                id: "menu.assignment.settings.fields.create",
                defaultMessage: "Create Outcome"
              }),
              component: SubjectFieldsEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.assignment.settings.fields.edit", defaultMessage: "Edit Outcome"}),
              component: SubjectFieldsEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/questions",
          icon: ListAlt,
          name: intl.formatMessage({id: "menu.assignment.settings.questions.list", defaultMessage: "Questions"}),
          component: AssignmentQuestionList,
          views: [
            {
              path: "/create",
              name: intl.formatMessage({
                id: "menu.assignment.settings.questions.create",
                defaultMessage: "Create Question"
              }),
              component: AssignmentQuestionEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({
                id: "menu.assignment.settings.questions.edit",
                defaultMessage: "Edit Question"
              }),
              component: AssignmentQuestionEdit,
              hidden: true,
            },
          ]
        },
      ]
    },
    {
      path: "/mails",
      name: intl.formatMessage({id: "menu.assignment.mails", defaultMessage: "Mail log"}),
      icon: Mail,
      component: MailsList,
    },
    {
      path: "/sms",
      name: intl.formatMessage({id: "menu.assignment.sms", defaultMessage: "SMS log"}),
      icon: Sms,
      component: SmsList,
    },
    {
      path: "/dialer",
      name: intl.formatMessage({id: "menu.assignment.show_in_dialer", defaultMessage: "Show in dialer"}),
      icon: Phone,
      href: '/dialer/:assignmentID'
    },
  ].map(r => ({...r, path: prefix + r.path}));
  routes.unshift({
    path: "/assignment/create",
    icon: Assignment,
    name: intl.formatMessage({id: "menu.assignment.create", defaultMessage: "Create"}),
    component: AssignmentEdit,
    hidden: true,
  });
  return routes;
}
